:root
  --primary-color: #2A2618
  --color-primary: #2A2618
  --primary-r: 42
  --primary-g: 38
  --primary-b: 24
  --primary-h: 47
  --primary-s: 27.3%
  --primary-l: 12.9%
  --switch: 100%
:root
  --secondary-color: #000000
  --color-secondary: #000000
